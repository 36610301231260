import mapImage from "../img/map.png";

const Contact = () => {
  return (
    <section className="p-md-5 py-4" id="contact">
      <div className="container">
        <div className="row g-4 align-items-center justify-content-between">
          <div className="col-md">
            <h2 className="text-center mb-4">Contact Us</h2>
            <ul className="list-group list-group-flush lead">
              <li className="list-group-item">
                <span className="fw-bold">Main Location: </span> Baluwatar,
                Kathmandu
              </li>
              <li className="list-group-item">
                <span className="fw-bold">Mobile Phone: </span> 977-9865365670
              </li>
              <li className="list-group-item">
                <span className="fw-bold">Email: </span>
                swastiknepalconstruction@gmail.com
              </li>
              <li className="list-group-item">
                <a href="https://www.facebook.com/Swastiknepalconstruction">
                  <i className="bi bi-facebook text-dark mx-2"></i>
                </a>
                <a href="https://www.instagram.com/swastiknepalconstruction/">
                  <i className="bi bi-instagram text-dark mx-2"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md text-center">
            <a
              href="https://www.google.com/maps/dir/?api=1&destination=P8GM%2B2WC+Kathmandu+44600&travelmode=driving"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={mapImage}
                className="img-fluid rounded shadow"
                alt="Location Map"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
